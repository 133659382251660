<template>
    <div>
        <navbar/>
        <image-plateformes/>
        <plateformes-liste class="mt100"/>
        <bulle-contact/>
    </div>
</template>

<script>
import navbar from '../components/NavBar.vue';
import imagePlateformes from '../components/imagePlateformes.vue'
import PlateformesListe from '../components/PlateformesListe.vue';
import BulleContact from '../components/BulleContact.vue';
export default {
    components:{
        navbar,
        PlateformesListe,
        imagePlateformes,
        BulleContact,
    },
}
</script>