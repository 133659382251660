<template>
    <div>
        <div class="bulle" v-show="!scroll && !close ">
            <button class="close-button" @click="close=true"><v-icon id="icon">mdi-close</v-icon></button>
            <p>Réservez votre présentation Personnalisée !</p>
            <div class="image-container">
                <img src="../images/bulles.png" alt="bulle de dialogue">
            </div>
            <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
        </div>
        <button class="bullefermée" v-show="scroll || close" @click="close=false">
            <p>...</p>
        </button>
    </div>
   
</template>

<style scoped>
.bulle{
    background-color:rgba(112, 138, 196, 0.7);
    height: 300px;
    width: 300px;
    align-content: end;
    align-items: center;
    text-align: center;
    padding-bottom: 25px;
    border-radius: 20px;
    position: fixed; /* Fixer la position de la bulle */
    top: 60vh; /* Distance du haut de l'écran */
    right: 20px; /* Distance du côté droit de l'écran */
    z-index: 1000;
    display: flex;
    flex-direction: column; /* Pour que le contenu soit empilé verticalement */
    justify-content: space-between;
}
.bulle p{
    color: white;
    position: absolute;
    top: 25px;
}
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
}

.image-container{
    display: flex;
    justify-content: center;
    align-items: end;
    margin-bottom: 25px;
    flex-grow: 1; 
}

.image-container img {
    max-width: 70%; /* Assure que l'image ne dépasse pas le conteneur */
    max-height: 70%; /* Assure que l'image reste contenue */
}
.image-container img{
    width: 100%;
}

.bullefermée{
    background-color:rgba(112, 138, 196, 0.7);
    height: 60px;
    width: 60px;
    padding-bottom: 25px;
    border-radius: 20px;
    position: fixed; /* Fixer la position de la bulle */
    top: 90vh; /* Distance du haut de l'écran */
    right: 20px; /* Distance du côté droit de l'écran */
    z-index: 1000;
}

.bullefermée :hover{
    cursor: pointer;
}

.bullefermée p{
    color: white;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}


#icon {
    font-size: 24px;
    color: white !important;
}
</style>

<script>
export default{
    data(){
        return{
            close: false,
            scroll: true,
            scrollPosition: 0,
            maxScroll: 900
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.scrollPosition = window.scrollY;
            if(this.scrollPosition > 500){
                this.scroll = false
            }else{
                this.scroll = true
            }
        },
    }
}

</script>