<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" sm="10" lg="10" xl="8">
                <div class="plateformes-container">
                    <div class="list-container">
                        <ul class="row-between item-implant">
                            <li>
                                <button @click="button = 1"><h3>MiceTrack Pro</h3></button>
                                <div :class="{ 'active': button == 1 }"></div>
                                <p>Température et Comportement</p>
                            </li>
                            <li>
                                <button @click="button = 2"><h3>MiceTrack Lite</h3></button>
                                <div :class="{ 'active': button == 2 }"></div>
                                <p>Santé et Comportement</p>
                            </li>
                            <li>
                                <button @click="button = 3"><h3>MiceReader</h3></button>
                                <div :class="{ 'active': button == 3 }"></div>
                                <p>Santé et Comportement</p>
                            </li>
                        </ul>
                    </div>
                    <div class="implant-container-inferior row-around">
                        <div class="part-1">
                            <div class="image-implant-container">
                                <img src="../assets/illustrations/MiceTrackPro.png" alt="" :class="{ 'active': button == 1 }">
                                <img src="../assets/illustrations/MiceTrackLite.png" alt="" :class="{ 'active': button == 2 }">
                                <img src="../assets/illustrations/MiceReader.png" alt="" :class="{ 'active': button == 3 }">
                            </div>
                        </div>
                        <div class="part-2 ">
                            <div class="capteurs">
                                <h4 class="tilte-part">Données</h4>
                                <div class="row-around background-capteurs margin5">
                                    <ul class="list-capteurs">
                                        <li :class="{ 'activeico': true}">
                                            <img src="../assets/ico/mémoire.png" alt="" >
                                            <p>Passeport intégré</p>
                                        </li>
                                        <li :class="{ 'activeico': true}">
                                            <img src="../assets/ico/tremblements.png" alt="" >
                                            <p>Tremblements</p>
                                        </li>
                                    </ul>
                                    <ul class="list-capteurs">
                                        <li :class="{ 'activeico': button == 2 || button == 3 || button == 4 }">
                                            <img src="../assets/ico/coeur.png" alt="">
                                            <p>Rythme cardiaque</p>
                                        </li>
                                        <li :class="{ 'activeico': button == 3 || button == 4 }">
                                            <img src="../assets/ico/o2.png" alt="" >
                                            <p>Taux d'oxygène</p>
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>
                            <div class="description">
                                <div class="information-implant" :class="{ 'active': button == 1 }">
                                    <h2 class="title-implant">{{implant[0].title}}</h2>
                                    <p>{{implant[0].dimension}}</p>
                                    <p>{{implant[0].texte}}</p>
                                </div>
                                <div class="information-implant" :class="{ 'active': button == 2 }">
                                    <h2 class="title-implant">{{implant[1].title}}</h2>
                                    <p>{{implant[1].dimension}}</p>
                                    <p>{{implant[1].texte}}</p>
                                </div>
                                <div class="information-implant" :class="{ 'active': button == 3 }">
                                    <h2 class="title-implant">{{implant[2].title}}</h2>
                                    <p>{{implant[2].dimension}}</p>
                                    <p>{{implant[2].texte}}</p>
                                </div>
                                <div class="button-position">
                                    <router-link to="/contact" class="button-contact">{{ $t('button.contact') }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>

</template>

<script>
export default {
    data (){
        return{
            button : 1,
            implant:[
                {title : 'MTS Activité', dimension : '( 155 x 20 mm )', texte : 'Notre implant MTS Activité est un dispositif de pointe conçu pour surveiller en temps réel l\'activité, la température et le comportement des souris de laboratoire. Équipé de capteurs de température, d\'un gyroscope et d\'un accéléromètre, il permet de collecter des données précieuses telles que les tremblements, le sommeil, les interactions sociales et plus encore.'},
                {title : 'MTS ECG', dimension : '( 180 x 20 mm )', texte : 'Le MTS ECG est un implant innovant conçu pour mesurer en temps réel l\'activité électrique cardiaque des souris de laboratoire. Grâce à ses capteurs intégrés de température, de gyroscope, d\'accéléromètre et de ECG, il permet une analyse détaillée et précise du rythme cardiaque, tout en surveillant d\'autres aspects comportementaux comme les tremblements, le sommeil, et les interactions sociales.'},
                {title : 'MTS PPG', dimension : '( 195 x 20 mm )', texte : 'Le MTS PPG est un implant spécialisé dans la surveillance du rythme cardiaque et des niveaux d\'oxygène dans le sang des souris de laboratoire. Doté de capteurs de température, d\'un gyroscope, d\'un accéléromètre, ainsi que de la technologie PPG (photopléthysmographie), cet implant permet de collecter des données précises sur la tension artérielle, le taux d\'oxygène, et l\'activité comportementale des sujets. Compact et conçu pour une implantation facile, le MTS PPG est une solution idéale pour les chercheurs qui souhaitent obtenir des données physiologiques et comportementales complètes.'},
            ]
        }
    }
}
</script>

<style scoped>

ul{
    padding-left: 0px  !important;
}

.plateformes-container{
    border-radius: 25px;
    background-color: white;
    border: solid 1px var(--noirdoux);
    padding: 20px;
    width: fit-content;
}

.list-container{
    margin-top: 25px;
}

.item-implant li{
    text-align: center;
}

.item-implant button:hover{
    opacity: 0.7;
}

.item-implant li div{
    width: 0px;
    height: 3px;
    background-color: black;
    transition: width 0.5s ease; /* Animation sur la hauteur */
}

.item-implant li div.active {
  width: 100%; /* Hauteur finale lors de l'animation */
}

.implant-container-inferior{
    margin-top: 55px;
}
.part-1{
    width: 45%;
}

.part-2{
    width: 45%;
}

.tilte-part{
    margin-bottom: 15px !important;
}

.image-implant-container {
    position: relative;
    text-align: center;
    align-content: center;
}

.image-implant-container img {
    position: relative;
    z-index: 2;
    width: 80%;
    display: none;
}

.image-implant-container img.active {
    display: block;
}

.list-capteurs{
    padding-left: 0px !important;
    width: 75%;
}

.background-capteurs{
    background-color: var(--background);
    border-radius: 20px;
    border: solid 1px var(--noirdoux);
    width: 100%;
    min-width: fit-content;
    padding: 20px;
}

.button-position{
    display: flex; 
    justify-content: right;
    margin-top: 50px;
}

.list-capteurs li {
    display: flex;
    justify-content: left;
    align-items: center;
    opacity: 0;
    transform: translateX(-20px); /* Positionne les éléments légèrement hors de l'écran à gauche */
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.list-capteurs li.activeico {
    opacity: 1;
    transform: translateX(0);
}

.list-capteurs li img{
    width: 62px;
    height: 50px;
}

.list-capteurs li p{
    margin-top: 16px;
    margin-left: 10px;
    font-family: 'roboto', light;
    font-size: 16px;
}

.information-implant {
    margin-top: 30px;
    animation: fadeInUp 0.5s ease-in-out forwards;
    display: none;
}

.information-implant.active {
    display: block;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 960px){
    .implant-container{
        background-color: transparent;
        border: none;
        padding: 0;
    }
    .implant-container-inferior{
        margin-top: 50px;
    }
    
    .item-implant button{
        font-weight: 400;
        font-size: 28px;
    }
    .item-implant p{
        display: none;
    }
    .implant-container-inferior{
        display: block;
    }
    .part-2,
    .part-1{
        width: 100%;
    }
    
    .margin5{
        width: 90%;
        margin: 5%;
    }
    .capteurs{
        margin-top: 50px;
    }
    .description{
        margin-top: 50px;
    }
    .background-capteurs{
        background-color: white;
    }
    .image-implant-container{
        margin-top: 50px;
    }
    .image-implant-container img{
        width: 80%;
    }
    .tilte-part{
        text-align: center;
    }
    .information-implant {
        width: 90%;
        margin: 5%;
    }

    .button-position{
        display: flex; 
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 20px;
    }
    
    .list-capteurs {
        width: 100%;
    }
    
}



</style>